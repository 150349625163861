






























import {defineComponent} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import STextarea from '@/common/components/STextarea.vue';

export default defineComponent({
  name: 'UCIrvineChem1LDELN1OIArgumentation1',
  components: {STextarea, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        argumentation2Answer: null,
      },
      attachments: [] as File[],
      questions: [
        {
          text: 'Summarize other teams’ critiques of your team’s poster & your critique of other teams’ posters. Describe how the information exchange in the poster session will affect your lab report’s content.',
          inputName: 'argumentation2Answer',
        },
      ],
    };
  },
});
